import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Navigation = () => {
  const primaryColor = '#766676';
  const lightColor = '#ffffff';

  const [anchorEl, setAnchorEl] = useState(null);
  const [assessmentsMenuOpen, setAssessmentsMenuOpen] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAssessmentsMenuClick = (event) => {
    setAssessmentsMenuOpen(event.currentTarget);
  };

  const handleAssessmentsMenuClose = () => {
    setAssessmentsMenuOpen(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: `${primaryColor}80`, backdropFilter: 'blur(5px)', top: 0, zIndex: 100 }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button color="inherit" style={{ color: lightColor, fontSize: '1.2rem', fontWeight: 'bold' }}>
              Primordial Health Coaching
            </Button>
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile? (
            <>
              <Button
                color="inherit"
                style={{ color: lightColor }}
                onClick={handleAssessmentsMenuClick}
              >
                Assessments
              </Button>
              <Menu
                id="assessments-menu"
                anchorEl={assessmentsMenuOpen}
                open={Boolean(assessmentsMenuOpen)}
                onClose={handleAssessmentsMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleAssessmentsMenuClose}>
                  <Link to="/primal-diet" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Primal Diet
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleAssessmentsMenuClose}>
                  <Link to="/hlc" style={{ textDecoration: 'none', color: 'inherit' }}>
                    HLC
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleAssessmentsMenuClose}>
                  <Link to="/chek-assessment" style={{ textDecoration: 'none', color: 'inherit' }}>
                    CHEK Assessment
                  </Link>
                </MenuItem>
              </Menu>

              <Button color="inherit" style={{ color: lightColor }}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                  About
                </Link>
              </Button>
              <Button color="inherit" style={{ color: lightColor }}>
                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Contact
                </Link>
              </Button>
            </>
          ): (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Home
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                  About
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/chek-assessment" style={{ textDecoration: 'none', color: 'inherit' }}>
                  CHEK Assessment
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/hlc" style={{ textDecoration: 'none', color: 'inherit' }}>
                  HLC
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/primal-diet" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Primal Diet
                </Link>
              </MenuItem>
            </Menu>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navigation;